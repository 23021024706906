<template>
  <button @click="handleTap" class="exit-button">
        <Exit class="tw-text-white tw-w-5 tw-h-5" />
  </button>
</template>

<script>
import Exit from '@/assets/destinationPage/icons/exit.svg';
export default {
  name: 'ExitButton', 
  components: {
    Exit,
  },
  props: {
    handleTap: Function,
  }

};
</script>

<style lang="scss" scoped>

.exit-button {
  @apply tw-bg-gray-300 tw-bg-opacity-50 tw-rounded-full tw-p-2;
}

</style>
