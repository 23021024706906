<template>
  <div 
    :style="{
      backgroundImage: 'url(' + currentExperience.background_image + ')', 
      backgroundColor: 'rgba(0,0,0,0.75)'
      }" 
    class="tw-min-h-full tw-min-w-full tw-bg-cover tw-bg-center tw-relative tw-h-screen tw-w-screen tw-flex tw-flex-col tw-justify-between hidden-xs-only">
    <div class="overlay" />
    <div class="tw-relative tw-z-10 tw-flex tw-flex-row tw-justify-between tw-items-center tw-text-white tw-p-4">
      <div class="tw-flex tw-flex-row tw-items-center">
        <div
          :style="{ 'background-image': `url(${currentExperience.influencer_data.image})` }"
          class="tw-rounded-full tw-w-12 tw-h-12 tw-bg-cover tw-shadow-xl"
        />
        <div class="tw-ml-2 tw-mr-6">
          <h4 class="tw-text-lg">
            {{  currentExperience.influencer_data.first_name }}
          </h4>
          <div>
          {{ Math.ceil(currentExperience.score * 100) }}% trip match
          </div>
        </div>
      </div>
      <button @click="goToInspirationPage(currentExperience)" class="discover-button">
        Discover
      </button>
      <ExitButton :handleTap="closeGallery" />
    </div>
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-z-10 tw-px-2">
      <button @click="prevSlide" :class="{ 'tw-opacity-0': this.currentExperienceIndex === 0 }" >
        <ArrowLeft class="tw-w-8 tw-h-8" />
      </button>
      <h3 class="tw-text-4xl tw-font-serif tw-text-white tw-text-center">{{currentExperience.name}}</h3>
      <button @click="nextSlide" :class="{ 'tw-opacity-0': this.currentExperienceIndex === this.filteredExperiences.length - 1 }">
        <ArrowRight class="tw-w-8 tw-h-8" />
      </button>
    </div>
    <div class="tw-ml-4 tw-mr-6 pb-12 tw-z-10">
      <MobileGallerySlider :filteredExperiences="filteredExperiences" @cardChanged="goToExperience" :currentExperienceId="currentExperienceId" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ExitButton from '@/views/components/ExitButton.vue';
import MobileGallerySlider from '@/views/destinationFeed/mobile/MobileGallerySlider.vue';
import ArrowRight from '@/assets/destinationPage/icons/arrow_right.svg';
import ArrowLeft from '@/assets/destinationPage/icons/arrow_left.svg';

export default {
  name: 'MobileGalleryExperiences',
  components: {
    ExitButton,
    MobileGallerySlider,
    ArrowRight,
    ArrowLeft
  },
  data() {
    return {
      filteredExperiences: [],
      currentExperienceId: ''
    };
  },
  computed: {
    ...mapGetters([ 'experiences' ]),
    currentExperience() {
      return this.filteredExperiences.find(experience => experience.exp_id === this.currentExperienceId);
    }, 
    currentExperienceIndex() {
      return this.filteredExperiences.findIndex(experience => experience.exp_id === this.currentExperienceId);
    }, 
  }, 
  methods: {
    ...mapActions([ 'loadExperiences' ]),
    goToInspirationPage: function(experience) {
      this.$router.push({
        name: 'InspirationPage',
        params: {
          inspirationId: experience.exp_id,
        },
      });
    },
    closeGallery: function() {
      this.$router.go(-1);
    },
    goToExperience: function(experienceId) {
      this.currentExperienceId = experienceId;
    },
    prevSlide() {
      if (this.currentExperienceIndex === 0) {
        return;
      }
      this.currentExperienceId = this.filteredExperiences[this.currentExperienceIndex - 1].exp_id;
    },
    nextSlide() {
      if (this.currentExperienceIndex >= this.filteredExperiences.length - 1) {
        return;
      }
      this.currentExperienceId = this.filteredExperiences[this.currentExperienceIndex + 1].exp_id;
    }
  },
  async mounted() {
    if (!this.experiences?.length) {
      let interests = this.$store.state.search.interests;
      interests.length < 1 && (interests = '1-2-3-4-5-6-7-8-9');
      await this.loadExperiences(interests);
    }

    const { cityName } = this.$route.params;
    const city = this.experiences.find(experience => experience.name === cityName);

    if (!city) {
      this.$router.push({ name: 'LandingPage' });
    }
    
    this.filteredExperiences = city.experiences;

    // load inital exp
    this.currentExperienceId = this.filteredExperiences[0].exp_id;

  },
};
</script>

<style lang="scss" scoped>
  .discover-button {
    @apply tw-border tw-border-white tw-border-solid tw-rounded-lg tw-py-2 tw-px-3;
  }

  .overlay {
    @apply tw-absolute tw-top-0 tw-h-full tw-w-full tw-left-0 tw-bg-black tw-opacity-30
  }

</style>
